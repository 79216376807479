import 'lodash';
import angular from 'angular';
import 'angular-ui-bootstrap';
import 'angular-bootstrap-datetimepicker';
// Exposes the rzslider directive
import 'angularjs-slider';
// Exposes the base-sixty-four-input directive
import 'angular-base64-upload';
// Exposes the us-spinner directive
import 'angular-spinner';
import 'angular-google-analytics';
// Exposes the wizard directive
import 'angular-wizard';

import 'angular-bootstrap-toggle';
import 'angular-date-time-input';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';

extendMoment(moment);

import 'moment-duration-format';
import 'ngstorage';
import 'spin.js';

import 'sweetalert2';

import env from './env';
import { templates } from './templates';
import serviceModule from './services/index';
import controllerModule from './controllers/index';
import directiveModule from './directives/index';
import filterModule from './filters/index';
import permissions from './permissions';

import version from './version';

// Declare app level module which depends on views, and components
export const app = angular
  .module('ServetureWebApp', [
    templates.name,
    'ui.bootstrap',
    'angularUtils.directives.dirPagination',
    'ngStorage',
    'isteven-multi-select',
    'ui.bootstrap.datetimepicker',
    'rzSlider',
    'ngAutocomplete',
    'naif.base64',
    'angularSpinner',
    'calendar-schedule-timeline',
    'angular-google-analytics',
    'mgo-angular-wizard',
    'ui.toggle',
    'ui.dateTimeInput',
    serviceModule.name,
    controllerModule.name,
    directiveModule.name,
    filterModule.name,
  ])
  .constant('BASE_URL', env.BASE_URL)
  .constant('DOMAIN', env.DOMAIN)
  .constant('WEB_URL', env.WEB_URL)
  .constant('APP_TOKEN', env.APP_TOKEN)
  .constant('ADMIN_API', env.ADMIN_API)
  .constant('WS_URL', env.WS_URL)
  .constant('DISPLAY_WEB_URL', env.DISPLAY_WEB_URL)
  .constant('ANALYTICS_CODE', env.ANALYTICS_CODE)
  .constant('PENTAHO_URL', env.PENTAHO_URL)
  .constant('PERMISSIONS', permissions)
  .constant('VERSION', version)
  .constant('ALLOW_JOB_ORDER_REPEAT', env.ALLOW_JOB_ORDER_REPEAT)
  .config([
    '$httpProvider',
    '$tooltipProvider',
    'AnalyticsProvider',
    function ($httpProvider, $tooltipProvider, AnalyticsProvider) {
      $httpProvider.interceptors.push([
        '$q',
        '$localStorage',
        function ($q, $localStorage) {
          return {
            request: function (config) {
              if (!config.skipHeaders) {
                config.headers = config.headers || {};
                if ($localStorage.token) {
                  config.headers.Authorization = 'Token ' + $localStorage.token;
                  config.headers['Content-Type'] = 'application/json; text/html; charset=utf-8';
                  config.headers.Accept = 'application/json; version=1.1';
                  config.headers['X-Framework'] = 'angularjs';
                } else {
                  config.headers['Content-Type'] = 'application/json';
                  config.headers.Accept = 'application/json; version=1.1';
                  config.headers['X-Framework'] = 'angularjs';
                }
              }
              return config;
            },
            responseError: function (response) {
              if (response.data && response.data.detail === 'Invalid token.') {
                delete $localStorage.token;
              }
              if (response.status === 401 || response.status === 403) {
                location.href = '/#/login?showModal=true';
                return;
              }
              return $q.reject(response);
            },
          };
        },
      ]);
      $tooltipProvider.setTriggers({
        click: 'blur',
      });

      AnalyticsProvider.setAccount(env.ANALYTICS_CODE);
    },
  ]);

angular.module('ServetureWebApp').config([
  '$locationProvider',
  function ($locationProvider) {
    $locationProvider.hashPrefix('');
  },
]);
