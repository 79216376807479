import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import Env from '../../scripts/env.js';
import { Observable } from 'rxjs';

@Injectable()
export class SecureHttpService {
  constructor(private http: HttpClient) {}

  private getHeaders() {
    let headers = new HttpHeaders();
    let token = localStorage.getItem('ngStorage-token');
    token = token.replace(/"/g, '');
    headers = headers.append('Authorization', `Token ${token}`);
    headers = headers.append('Accept', 'application/json; version=1.1');
    headers = headers.append('X-Framework', 'Angular');
    return headers;
  }

  private resolveUrl(endpoint: string) {
    if (endpoint.startsWith('http://') || endpoint.startsWith('https://')) {
      return endpoint;
    } else {
      return `${Env.BASE_URL}/${endpoint}`;
    }
  }

  get(endpoint, params?) {
    return this.http.get(this.resolveUrl(endpoint), {
      headers: this.getHeaders(),
      params,
    }) as Observable<any>;
  }

  post(endpoint, data?) {
    return this.http.post(this.resolveUrl(endpoint), data, {
      headers: this.getHeaders(),
    }) as Observable<any>;
  }

  postNoToken(endpoint, data?) {
    return this.http.post(this.resolveUrl(endpoint), data) as Observable<any>;
  }

  patch(endpoint, data) {
    return this.http.patch(this.resolveUrl(endpoint), data, {
      headers: this.getHeaders(),
    }) as Observable<any>;
  }

  delete(endpoint, data?) {
    return this.http.request('delete', this.resolveUrl(endpoint), {
      body: data,
      headers: this.getHeaders(),
    }) as Observable<any>;
  }

  postFile(endpoint, data) {
    const headers = this.getHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(this.resolveUrl(endpoint), data, {
      headers,
    }) as Observable<any>;
  }

  postLocalhost(endpoint, data) {
    return this.http.post(`http://localhost:3001/${endpoint}`, data, {
      headers: this.getHeaders(),
    }) as Observable<any>;
  }
}
