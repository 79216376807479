import module from './module';
import moment from 'moment';

export default module.directive('dirScheduleJobForm', function () {
  function alphabetical(a, b) {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }
  }
  return {
    restrict: 'E',
    scope: {
      field: '=',
      ent: '=',
      index: '=',
      selectedJob: '=',
      setFormValidity: '&',
      warningFields: '=',
      warningFieldsFocus: '=',
    },
    controller: [
      '$scope',
      '$rootScope',
      '$location',
      'locationManagerService',
      'DOMAIN',
      'FormatHelperService',
      'LocationProvider',
      function ($scope, $rootScope, $location, locationManagerService, DOMAIN, FormatHelperService, LocationProvider) {
        const imageLoader = (file) => {
          if (file.type.indexOf('image') != -1) {
            if (FileReader) {
              const _canvasId = $scope.field.name + '_' + $scope.index;
              let canvas = document.getElementById(_canvasId);
              if (!canvas) {
                canvas = document.createElement('canvas');
              }
              const context = canvas.getContext('2d');

              // load default image
              if (file.default_text) {
                // Draw image to canvas in order to convert to jpeg and resize
                const imageObj = new Image();
                imageObj.onload = function () {
                  canvas.width = 856;
                  canvas.height = 1147;
                  context.drawImage(imageObj, 0, 0, 856, 1147);
                  $scope.field.jpeg = canvas.toDataURL('image/png', 0.75).replace(/^data:image\/(png);base64,/, '');
                  $scope.imageUrl = canvas.toDataURL();
                  $scope.showRemoveBtn = true;
                  $scope.$apply();
                };

                const request = new XMLHttpRequest();
                request.open('GET', file.default_text, true);
                request.responseType = 'blob';
                request.onload = function () {
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    imageObj.src = e.target.result;
                  };
                  reader.readAsDataURL(request.response);
                };
                request.send();
              } else {
                // load image by user
                // Draw image to canvas in order to convert to jpeg and resize
                const imageObj = new Image();
                imageObj.onload = function () {
                  canvas.width = 856;
                  canvas.height = 1147;
                  context.drawImage(imageObj, 0, 0, 856, 1147);
                  $scope.field.jpeg = canvas.toDataURL('image/jpeg', 0.75).replace(/^data:image\/(jpeg);base64,/, '');
                  $scope.imageUrl = canvas.toDataURL();
                  $scope.$apply();
                };

                // File Reader to set image obj
                const fr = new FileReader();
                fr.onload = function () {
                  imageObj.src = fr.result;
                };
                fr.readAsDataURL(file);
              }
            } // end if FileReader Present

            $scope.showRemoveBtn = true;
            delete $scope.imageError;
            delete $scope.imageUrl;
          } else {
            $scope.removeFile();
            $scope.imageError = 'Only images are allowed';
          }
        };

        $scope.onImageChangeHandler = function (event, file) {
          imageLoader(file[0]);
        };

        $scope.removeFile = function (e) {
          e ? e.preventDefault() : null;
          const _name = $scope.field.name + '_' + $scope.index.toString();
          $('input[name=' + _name + ']').val(null);
          delete $scope.field.value;
          delete $scope.imageUrl;
          $scope.field.jpeg ? delete $scope.field.jpeg : null;
          $scope.showRemoveBtn = false;
        };

        $scope.addAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.$emit('addAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.removeAdditionalAttrib = function (e) {
          e ? e.preventDefault() : null;
          $scope.removeFile();
          $scope.$emit('removeAttribInstanceClicked', $scope.field, $scope.index);
        };

        $scope.addInstanceBtnDisplay = function () {
          const _count = $scope.field.instanceCountIndex || 1;
          if (_count == $scope.field.instance_count || $scope.field.doNotShowAddInstance) {
            return false;
          } else {
            return true;
          }
        };
        $scope.removeInstanceBtnDisplay = function () {
          if ($scope.field.showRemoveInstance && $scope.field.instanceCountIndex) {
            return true;
          }
        };

        $scope.multipleInstances = function () {
          if ($scope.field.instance_count && $scope.field.instance_count != 1) {
            return true;
          }
        };

        $scope.onMouseDown = (event, field) => {
          if ($scope.warningFields && $scope.warningFields.indexOf(field.name) > -1) {
            $scope.warningFieldsFocus(event);
          }
        };
        /// ///////////////////////////////////////////////////////////////////////////
        // switch case based on field type
        switch ($scope.field.type) {
          case 'single_list':
            if (!$scope.field.value && $scope.field.choice_list) {
              var selected = $scope.field.choice_list.filter(function (item) {
                return item.selected;
              });
              if (selected) {
                $scope.field.value = selected[0];
              }
            }
            break;
          case 'multi_list':
            break;
          case 'location':
            var selected = locationManagerService.getSelectedLocation();
            $scope.$watch('field.value', function (newVal, oldVal) {
              // if (newVal !== oldVal) {
              locationManagerService.setLocation($scope.field.value);
              $rootScope.$emit('locationChanged', $scope.field.value);
              // }
            });
            $scope.$watch('field.value.location', function (newVal, oldVal) {
              if (newVal !== oldVal) {
                $rootScope.$emit('locationChanged', $scope.field.value.location);
              }
            });
            locationManagerService.getAllLocations($scope.ent.enterprise_id).then(function (locationsObject) {
              if ((locationsObject && $scope.ent.enterprise_id != -1) || $location.path()?.includes('job-order')) {
                let locations;
                if ($scope.ent.enterprise_id != -1) {
                  locations = locationsObject[$scope.ent.enterprise_id];
                } else if ($location.path()?.includes('job-order')) {
                  locations = [].concat.apply(
                    [],
                    Object.keys(locationsObject).map((k) => locationsObject[k])
                  );
                }
                $scope.locations = LocationProvider.handleOtherLocation(locations);
                $scope.locations.sort(alphabetical);
                $scope.field.locations = $scope.locations;
                if (selected.name && selected.location_id) {
                  $scope.field.value = $scope.locations.filter(function (obj) {
                    return obj.location_id == selected.location_id;
                  })[0];
                } else if (selected.name && !selected.location_id) {
                  $scope.locations.push(selected);
                  $scope.field.value = selected;
                }
              } else {
                $scope.locations = $scope.handleOtherLocation([]);
                $scope.field.value = $scope.locations[0];
              }
            });

            $scope.locationChanged = function () {
              if ($scope.field.value.location_id == 0) {
                $scope.setFormValidity({
                  valid: false,
                });
              }
            };

            $scope.otherChanged = function () {
              if ($scope.field.valueBackup) {
                $scope.setFormValidity({
                  valid: true,
                });
              } else {
                $scope.setFormValidity({
                  valid: false,
                });
              }
            };
            break;
          case 'place':
            var requiredType = $scope.field.type_flags.required;

            $rootScope.$on('locationChanged', function (event, location) {
              if (!location) {
                return;
              }
              if (
                !$scope.location ||
                $scope.location.location_id !== location.location_id ||
                !$scope.location.place_list
              ) {
                $scope.location = location;
                $scope.place_list = location.place_list;
                $scope.checkPlace();
              }
            });

            // if user selects other then hide place. If unselects then reshow
            $scope.checkPlace = function () {
              if ($scope.location.name == 'Other') {
                $scope.field.type = null;
                $scope.field.type_flags.required = false;
              } else {
                $scope.field.type = 'place';
                $scope.field.type_flags.required = requiredType;
              }

              if (!$scope.selectedJob || !$scope.place_list || !$scope.place_list.length) {
                return;
              }

              const place = $scope.selectedJob.attrib_list.find((a) => a.attrib_id === 6);
              if (!place) {
                return;
              }

              if ($scope.place_list && $scope.place_list.length) {
                const pl = place.value_place.find((p) => p.selected);
                $scope.field.value = pl;
              }
            };
            break;
          case 'text':
            if ($scope.field.max_length < 100) {
              $scope.rows = 1;
            } else {
              $scope.rows = 4;
            }
            // if approving
            if ($scope.field.value_text) {
              $scope.field.value = $scope.field.value_text;
            } else if ($scope.field.default_text && !$scope.field.value) {
              $scope.field.value = $scope.field.default_text;
            } else if ($scope.selectedJob && $scope.field.attrib_id === 321) {
              $scope.field.value = $scope.selectedJob.desc;
            }
            break;
          case 'count':
            if ($scope.field.name == 'max_eta') {
              $scope.field.value = $scope.field.default_count || 0;

              $scope.sliderModel = {
                options: {
                  floor: $scope.field.min_amount,
                  ceil: $scope.field.max_amount,
                  step: $scope.field.inc_amount,
                  showTicks: false,
                  translate: function (value) {
                    return FormatHelperService.generateDurationLabel(value);
                  },
                },
              };
              // if approving
              if ($scope.field.value_count) {
                $scope.field.value = $scope.field.value_count;
              }
            } else if ($scope.field.name == 'duration') {
              $scope.field.value = $scope.field.value || $scope.field.default_count || 0;

              $scope.sliderModel = {
                options: {
                  floor: $scope.field.min_amount,
                  ceil: $scope.field.max_amount,
                  step: $scope.field.inc_amount,
                  showTicks: false,
                  translate: function (value) {
                    return FormatHelperService.generateDurationLabel(value);
                  },
                },
              };
              // if approving
              if ($scope.field.value_count) {
                $scope.field.value = $scope.field.value_count;
              }
            } else if ($scope.field.name == 'scheduled_wait_time') {
              $scope.field.value = $scope.field.default_count || 0;

              $scope.sliderModel = {
                options: {
                  floor: $scope.field.min_amount,
                  ceil: 1440,
                  step: 15,
                  showTicks: false,
                  translate: function (value) {
                    return FormatHelperService.generateDurationLabel(value);
                  },
                },
              };
              // if approving
              if ($scope.field.value_count) {
                $scope.field.value = $scope.field.value_count;
              }
            } else if ($scope.field.name == 'on_demand_wait_time') {
              $scope.field.value = $scope.field.default_count || 0;

              $scope.sliderModel = {
                options: {
                  floor: $scope.field.min_amount,
                  ceil: 180,
                  step: 5,
                  showTicks: false,
                  translate: function (value) {
                    return FormatHelperService.generateDurationLabel(value);
                  },
                  onChange: function () {
                    $scope.onFocus($scope.field);
                  },
                },
              };
              // if approving
              if ($scope.field.value_count) {
                $scope.field.value = $scope.field.value_count;
              }
            }
            break;
          case 'decimal':
            if ($scope.field.value_decimal) {
              $scope.field.value = $scope.field.value_decimal;
            } else if ($scope.field.default_text && !$scope.field.value) {
              $scope.field.value = $scope.field.default_decimal || 0;
            }
            break;
          case 'date_time':
            $scope.status = {
              opened: false,
            };
            $scope.open = function ($event) {
              $scope.status.opened = !$scope.status.opened;
            };

            // if approval
            if ($scope.field.value_text) {
              // Takes UTC date and parses to local
              const date = moment.utc($scope.field.value_text, 'MMDDYYYY HH:mm').local();
              $scope.field.value = date;
            }
            break;
          case 'flag':
            // if approval
            if ($scope.field.data) {
              $scope.field.value = $scope.field.data;
            }
            $scope.field.value = $scope.field.value || $scope.field.default_flag;
            break;
          case 'image':
            if ($scope.field && $scope.field.value) {
              updateImage($scope.field.value);
            } else if ($scope.field && $scope.field.default_text) {
              const field = $scope.field;
              field.default_text =
                field.default_text.indexOf(DOMAIN) === -1 ? DOMAIN + field.default_text : field.default_text;
              imageLoader(field);
              // updateImage($scope.field.default_text);
            }
            break;
        }

        function updateImage(value) {
          $scope.imageUrl = value.indexOf(DOMAIN) === -1 ? DOMAIN + value : value;
          $scope.showRemoveBtn = true;
        }
      },
    ],
    templateUrl: 'app/views/templates/schedule_job_form_fields.html',
  };
});
